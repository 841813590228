import $ from 'jquery';
import {formActionchanging} from "lib/form_action_changing";
import {scrollAnimation} from "lib/scroll_animation";
import 'slick-carousel';
import Rellax from 'rellax';
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import {faSearch,faFolder,faFeather,faTag,faPaw,faKey,faCogs,faThumbsUp,faThumbsDown} from '@fortawesome/free-solid-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
//import {faComments} from '@fortawesome/free-regular-svg-icons';
library.add(faSearch,faFolder,faFeather,faTag,faPaw,faKey,faCogs,faThumbsUp,faThumbsDown,faTwitter);
dom.i2svg();


var rellax = new Rellax('.rellax',{
	speed: 8,
	zindex: -1
});

const toggleNavBox = ()=>{
	"use strict";
	const $trigger = $(".menu-trigger");
	$trigger.parent().toggleClass("on_animation");
	$trigger.toggleClass("active");
	$trigger.next().slideToggle(500);
	$('.gblnv_list li').hide();
	$('.gblnv_list li').each(function(i) {
		$trigger.delay(80 * i).fadeIn(500);
	});
	$('.popup_shadow').fadeToggle(300);
};

$(()=>{
	"use strict";

	$('#slide_pic').slick({
		centerMode: true,
		centerPadding: '120px',
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 7000,
	});

	$('.menu-trigger , .popup_shadow').on('click', ()=>{
		toggleNavBox();
	});


	const $selecter = $('.category-selecter'),
		  $target_form = $('.search-box-form');
	let $num = $selecter.prop("selectedIndex");
	//firefoxの<selecter>が初期値にならない不具合への対策
	$selecter.prop("selectedIndex", $num);
	formActionchanging($selecter, $target_form, $num);

	//対象セレクタと発火する高さを代入
	scrollAnimation.cleared($('.wallpaper'),150);
	//対象セレクタとヘッダーを代入
	scrollAnimation.navFixed($('#menubar'),$('header'));
});
