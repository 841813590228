import jquery from 'jquery'; 
window.$ = jquery 
export const scrollAnimation = {
	templa: (trrigerHeigt,addActions)=>{
		"use strict";
		const $win = $(window);
		$win.on('load scroll', ()=> {
			const winHeigt = $(window).scrollTop();
			if ( winHeigt > trrigerHeigt ) {
				addActions.forEach((action)=>{
					action.selecter.css(action.changedCss,action.afterPoint);
				});
			} else {
				addActions.forEach((action)=>{
					action.selecter.css(action.changedCss,action.beforPoint);
				});
			}
		});
	},
	
	cleared :($target ,trrigerHeigt)=> {
		"use strict";
		const addActions =[{selecter:$target,changedCss:'opacity',afterPoint:'0',beforPoint:'1'}];
		scrollAnimation.templa(trrigerHeigt,addActions);
	},
	navFixed : ($target,$trriger)=> {
		"use strict";
	 	const
			trrigerHeigt 	= $target.offset().top,
			addActions =[
				{selecter:$target,	changedCss:'position',	afterPoint:'fixed',		beforPoint:'absolute'},
				{selecter:$target,	changedCss:'top',		afterPoint:'0',		beforPoint:'60px'},
				{selecter:$trriger,	changedCss:'opacity',	afterPoint:'0',			beforPoint:'1'}
			];
		scrollAnimation.templa(trrigerHeigt,addActions);
	}
};