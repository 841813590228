export const formActionchanging = ($selecter, $target_form, $num)=>{
	'use strict';
	$selecter.on('click',function (){
		$num = $(this).prop("selectedIndex");
		let $category = $(this).children()[$num].value;
		console.log('click');
		if($num === 0){
			$target_form.attr('action', `/articles/`);
		}else{
			$target_form.attr('action', `/articles/${$category}/`);
		}
	});
};